<template>
  <default-layout>
    <portal to="pageHeaderRight">
      <submit-button
        :isSaving="isSaving"
        class="float-right"
        v-if="$store.state.is_brand_manager"
      />
    </portal>
    <portal to="sidebarContent">
      <div class="sidebar-left__content-header">
        {{ $t("app.navigation") }}
      </div>
      <div class="sidebar-left__menu">
        <ul>
          <li>
            <router-button
              routeName="InterfaceSettingsColors"
              :text="$t('interface.colors')"
            ></router-button>
          </li>
          <li>
            <router-button
              routeName="InterfaceSettingsButtons"
              :text="$t('interface.buttons')"
            ></router-button>
          </li>
          <li>
            <router-button
              routeName="InterfaceSettingsFonts"
              :text="$t('interface.fonts')"
            ></router-button>
          </li>
          <li>
            <router-button
              routeName="InterfaceSettingsLoginScreen"
              :text="$t('interface.login_screen')"
            ></router-button>
          </li>
          <li>
            <router-button
              routeName="InterfaceSettingsNavigation"
              :text="$t('interface.navigation')"
              v-if="$store.state.is_brand_manager"
            ></router-button>
          </li>
        </ul>
      </div>
    </portal>
    <div class="grid-x grid-margin-x">
      <div class="cell medium-12 page__inner-content">
        <form id="editInterfaceSettings" @submit="formSubmit">
          <input type="submit" id="submitForm" hidden="" />
          <vue-page-transition name="fade">
            <router-view :content="content"></router-view>
          </vue-page-transition>
        </form>
      </div>
    </div>
  </default-layout>
</template>

<script>
import backend from '@/backend'

export default {
  name: 'InterfaceSettings',
  data () {
    return {
      content: {},
      isSaving: false
    }
  },
  created () {
    this.content = this.$store.state.interface_settings
  },
  methods: {
    formSubmit (e) {
      e.preventDefault()
      this.isSaving = true
      backend
        .post(`${process.env.VUE_APP_URL}/interface_settings`, {
          settings: this.content
        })
        .then(response => {
          this.$store.state.interface_settings = response.data
          this.content = response.data
          this.$notify({
            group: 'custom-template',
            title: false,
            position: 'bottom left',
            text: this.$t('notify.changes_saved')
          })

          // refresh favicon in html head
          if (this.content.login_screen_favicon.faviconIsAdded) {
            this.updateFavicon(response.data)
          }

          // delete older formats if favicon has removed
          if (this.content.login_screen_favicon.faviconIsRemoved) {
            this.showDefaultFavicon()
          }

          // delete state add/remove states
          delete this.content.login_screen_favicon.faviconIsAdded
          delete this.content.login_screen_favicon.faviconIsRemoved

          this.isSaving = false
        })
        .catch(() => {
          this.isSaving = false
        })
    },
    updateFavicon (data) {
      // first delete older ones
      this.deleteOldFaviconFormats()

      const viewport = document.querySelector("[name='viewport']")
      const faviconFormats = this.content.login_screen_favicon.favicon_formats
      const keys = Object.keys(faviconFormats)
      console.log(keys)
      keys.forEach(key => {
        const item = faviconFormats[key]
        const faviconElement = this.createFaviconElement(
          item.path,
          item.type,
          item.size
        )

        viewport.insertAdjacentElement('afterEnd', faviconElement)
      })
    },
    deleteOldFaviconFormats () {
      Array.from(document.querySelectorAll("[rel='icon'],[rel='apple-touch-icon']")).map(el => {
        return el.remove()
      })
    },
    showDefaultFavicon () {
      const viewport = document.querySelector("[name='viewport']")
      let faviconElement = ''

      this.deleteOldFaviconFormats()
      faviconElement = this.createFaviconElement('/favicon.ico')

      viewport.insertAdjacentElement('afterEnd', faviconElement)
    },
    createFaviconElement (href, type, size) {
      type = type || 0
      size = size || 0

      const faviconElement = document.createElement('link')
      faviconElement.rel = 'icon'
      faviconElement.href = href

      if (type) {
        faviconElement.type = type
      }

      if (size) {
        faviconElement.sizes = size + 'x' + size

        if (size === '180') {
          faviconElement.rel = 'apple-touch-icon'
        }
      }

      return faviconElement
    }
  }
}
</script>
